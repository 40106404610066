@tailwind base;
@tailwind components;
@tailwind utilities;

div#col-left, div#col-right {
  max-height: 300px;          /* not available in Bootstrap */
  overflow:scroll;
  -webkit-overflow-scrolling: touch;
}

.list-group{
    max-height: 800px;
    margin-bottom: 10px;
    overflow:scroll;
    -webkit-overflow-scrolling: touch;
}